@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@font-face{font-family:'Sarcasm Ink';font-style:normal;font-weight:normal;src:url(/static/media/SarcasmInk.6a7f2e1e.otf) format("opentypw"),url(/static/media/SarcasmInk.b3984bc8.ttf) format("truetype")}body{--bs-body-bg: #f0a933;--bs-body-color: #320d09;--bs-body-font-family: Roboto, "Open Sans", Arial, sans-serif}body nav{font-family:Sarcasm Ink, serif;font-weight:bold;font-size:1.5em}body a,body .highlight{color:white !important}.page-content,.legal{max-width:732px !important;font-size:1.1em}.page-content{padding-top:120px}.hr-img{margin:auto;padding-bottom:1em;width:15% !important}.legal{font-size:10px}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.page-content>.Row img{max-width:100%}.page-content>.hello{font-family:Sarcasm Ink, serif;font-size:1.3em;font-weight:bold}.page-content>.hello #yogurt-main{max-height:350px}.title{font-family:Sarcasm Ink, serif;font-weight:bold}.title>.show-you{color:#FCDE87;font-size:2em}.title>.itmo-students{font-size:2em;color:white}a.team-member{width:auto}a.team-member>img{max-width:200px}

